<template>
  <div v-if="canAccess('contralor_actions')">
    <div class="p-grid">
      <div class=" p-col-12 card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <Toolbar class="p-col-12">

          <template #left>

            <div class="p-fluid filter p-ml-2">

              <div class="p-grid p-fluid filter p-ml-2">

                <div class="p-field p-fluid  p-mr-2">
                                    <span class="p-float-label">
                                        <InputText id="enroll" v-model="search" @keyup.enter="filter(search)"/>
                                        <label for="enroll">Buscar por</label>
                                    </span>
                </div>
                <div class="p-field p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown id="status" v-model="filters.status" :options="status"
                                                 @change="filter"/>
                                        <label for="status">Estado</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedCountryObj" :options="countries" optionLabel="country"
                                                 @change="selectedMonthObj=null;selectedWeekObj=null;pLiquidations=[];filter()"/>
                                        <label for="country">País</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedMonthObj" :options="months"
                                                 optionLabel="month_process"
                                                 @change="selectedWeekObj=null;pLiquidations=[];filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedWeekObj" :options="weeks" optionLabel="week_process"
                                                 @change="pLiquidations=[];filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
                </div>


                <div class="p-field   p-fluid  p-ml-2">
                  <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                          @click="reload"/>
                </div>
              </div>

            </div>
          </template>
        </Toolbar>
      </div>
      <DataTable :value="pLiquidations" :paginator="true" dataKey="id"
                 v-model:selection="currentItem" selectionMode="single"
                 @row-select="selectData($event)"
                 paginatorPosition="bottom"
                 :lazy="true"
                 :totalRecords="page.total"
                 :rows="page.per_page"
                 :loading="loading"
                 @page="onPage($event)"
                 ref="dataTableLiquidations"
      >
        <Column header="Cons. No" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <router-link :to="'/liquidation/validate/detail/'+slotProps.data.id">{{ slotProps.data.id }}</router-link>
            </div>
          </template>
        </Column>
        <Column header="Nombres Asesor" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.employee_name }}</div>
          </template>
        </Column>
        <Column header="Mes" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_month?.year_process }} -
              {{ slotProps.data.liquidation_month?.month_process }}
              {{ slotProps.data.enrollment?.contract?.holder?.last_name }}
            </div>
          </template>
        </Column>
        <Column header="Semana" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.liquidation_week?.week_process ?? '-' }}</div>
          </template>
        </Column>
        <Column header="Total" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.total) }}</div>
          </template>
        </Column>
        <Column header="Neto a Pagar" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ formatPrice(slotProps.data.totals.net_to_pay) }}</div>
          </template>
        </Column>
        <Column header="Fecha Generacion" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ $filters.dateFormat(slotProps.data.generate_at) }}</div>
          </template>
        </Column>
        <Column header="Estado" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="slotProps.data.status"
                   v-if="slotProps.data.status === approvedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="warning" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === validKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-info-circle" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === newKey"></Tag>
              <!-- <Tag class="p-mr-2" rounded icon="pi pi-info-circle" v-else :value="slotProps.data.status"></Tag> -->
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import liquidationValidateService from '../../service/liquidation_validation.service'
import monthWeekService from '../../service/mw.service'
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: "LiquidationList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      columns: [
        {field: 'code', header: 'Codigo'},
        {field: 'date_payment', header: 'Fecha'},
      ],
      page: {
        page: 1,
        per_page: 9,
        total: 0,
        order: "desc"
      },
      allKey: 'Todos',
      approvedKey: 'Aprobada',
      validKey: 'Validada',
      pLiquidations: [],
      data: {},
      selectedWeek: null,
      selectedMonth: null,
      filters: {'status': 'Todos'},
      submitted: false,
      loading: false,
      dialogo: false,
      verifyDataDialog: false,
      selected: false,
      selectedCountryObj: null,
      selectedMonthObj: null,
      selectedWeekObj: null,
      search: '',
      countries: [],
      actionFromStorage: false
    }
  },
  methods: {
    toDetail(idDetail) {
      this.$router.push('/liquidation/validate/detail/' + idDetail);
    },

    onPage(event) {
      this.page.page = event.page + 1;
      this.filter()
    },

    enable() {
      this.selected = true
    },

    getData(params = {}) {
      this.loading = true;
      const config = {
        ...this.page,
        ...params
      };
      liquidationValidateService.get(null, config).then(x => {
        this.pLiquidations = x.data.data;
        this.page.total = x.data.total;
      }).finally(() => this.loading = false)
    },
    reload() {
      this.selectedCountryObj = null;
      this.selectedWeekObj = null;
      this.selectedMonthObj = null;
      this.filters = {};
      this.filters = {'status': 'Todos'};
      this.search = '';
      localStorage.setItem('filters_validation', JSON.stringify({
        'status': 'Todos',
        'country': null,
        'month': null,
        'week': null,
        'search': '',
      }))
      this.getData();
    },
    formatPrice(priceInput) {
      let priceTransform = (priceInput / 1).toFixed(2).replace('.', ',')
      if (priceInput !== 0) {
        priceTransform = "$ " + priceTransform.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      return priceTransform
    },
    filter(search) {
      this.actionFromStorage = false;
      this.loading = true;
      localStorage.setItem('filters_validation', JSON.stringify({
        'status': this.filters.status ?? null,
        'country': this.selectedCountryObj?.id ?? null,
        'month': this.selectedMonthObj?.id ?? null,
        'week': this.selectedWeekObj?.id ?? null,
        'search': this.search ?? '',
      }))
      const country_id = this.selectedCountryObj ? this.selectedCountryObj.id : undefined;
      const month_id = this.selectedMonthObj ? this.selectedMonthObj.id : undefined;
      const week_id = this.selectedWeekObj ? this.selectedWeekObj.id : undefined;
      const status = this.filters.status !== 'Todos' ? this.filters.status : undefined;

      if (this.search !== '') {
        this.filters.status = this.allKey
        const params = {
          country: country_id,
          months: month_id, weeks: week_id, status, q: search, page: this.page.page
        };
        liquidationValidateService.get(undefined, params).then(x => {
          this.pLiquidations = x.data.data;
          this.page.total = x.data.total;
          this.page.page = x.data.current_page;
          this.page.per_page = parseInt(x.data.per_page + '');
        }).finally(() => this.loading = false)

      } else {
        const params = {
          country: country_id,
          months: month_id, weeks: week_id, status
        };
        this.getData(params)
      }
    },
    selectData() {
      this.toDetail(this.currentItem.id)
    },
    loadFiltersFromStorage() {
      this.actionFromStorage = true;
      const filtersInStorage = JSON.parse(localStorage.getItem('filters_validation') ?? JSON.stringify({}));
      monthWeekService.getMWLiq().then(response => {
        this.countries = response.data
        if (!this.isAdmin) {
          this.selectedCountryObj = this.countries[0];
        } else {
          this.selectedCountryObj = response.data.find((countryItem) => countryItem.id === filtersInStorage?.country) ?? null;
        }
        if (this.selectedCountryObj !== null) {
          this.selectedMonthObj = this.selectedCountryObj.months.find((monthItem) => monthItem.id === filtersInStorage?.month) ?? null;
        }
        if (this.selectedMonthObj !== null) {
          this.selectedWeekObj = this.selectedMonthObj.weeks.find((weekItem) => weekItem.id === filtersInStorage?.week) ?? null;
        }
        this.filters.status = this.status.find((status) => status === filtersInStorage?.status) ?? null;
        this.search = filtersInStorage?.search;
        this.filter(filtersInStorage?.search);
      })
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    status() {
      return [this.allKey,
        this.approvedKey,
        this.validKey]
    },
    months() {
      const arrayMonths = this.selectedCountryObj ? this.selectedCountryObj.months : [];
      if (arrayMonths.length === 0 && this.selectedCountryObj) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen meses asociados al país',
          life: this.$utils.toastLifeTime()
        });
      }
      return arrayMonths;
    },
    weeks() {
      const arrayWeeks = this.selectedMonthObj ? this.selectedMonthObj.weeks : [];
      if (arrayWeeks.length === 0 && this.selectedMonthObj) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen semanas asociadas al mes',
          life: this.$utils.toastLifeTime()
        });
      }
      return arrayWeeks;
    },
  },
  mounted() {
    if (this.canAccess('contralor_actions')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Lista de Liquidaciones por validar', url: "javascript:void(0);"},
      ]);
      this.loadFiltersFromStorage();
    }
  }
}
</script>

<style scoped lang="scss">

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.p-selectable-row td:first-child, .uc {
  padding-left: 1.5rem !important;
  display: inline-block;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}
</style>
